<template>
    <section class="steps-signup">
        <article class="looking-for-article">
            <div class="header o-flex o-flex--center o-flex--justify">
                <BackButton :callback="backButtonHandler" />
                <router-link :to="{name: 'home'}" class="header-logo">
                    <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                </router-link>

                <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
            </div>
            <h1>{{$t('signup.new_registration.looking_for')}}</h1>
            <p class="mb+">{{$t('signup.new_registration.select')}}</p>
            <ul class="list">
                <li v-for="(e, i) in LOOKING" :key="i">
                    <div class="checkbox">
                        <input type="checkbox" name='looking_for' :id="`meeting${i}`" :value="e.value" v-model="lookingFor">
                        <label :for="`meeting${i}`">
                            {{ e.code === 'selling_pics' ? $t(`signup.new_registration.interests.${e.code}.${gender === 'man' ? 'sd' : 'sb'}`) : $t(`signup.new_registration.interests.${e.code}`)}}
                        </label>
                    </div>
                </li>
            </ul>
            <div class="form-group submit-button">
                <button type="button" :disabled="working || disableButton" @click="nextStep" class="button button--primary w-100">
                    {{$t('signup.new_registration.continue')}}
                </button>
            </div>
        </article>
    </section>
</template>
<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import {interests, additional_interests} from "@/service/forms";
import LanguageSelection from "@/components/language/LanguageSelection";
import BackButton from "@/components/generic/BackButton";

export default {
    name: 'LookingFor',
    mixins: [ThemeMixin],
    components: {LanguageSelection, BackButton},
    emits: ['nextStep', 'previousStep'],
    props: {
        payload: Object,
        working: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            theme: '',
            lookingFor: [],
        }
    },
    computed: {
        disableButton() {
            return this.lookingFor.length === 0
        }
    },
    methods: {
        nextStep() {
            if( ! this.lookingFor.length )
                return

            this.$emit('nextStep', {interested_in: this.lookingFor})
        },
        backButtonHandler() {
            this.$emit('previousStep', {current: 'LookingFor'})
        },
    },
    created() {
        this.theme = process.env.VUE_APP_THEME
        this.LOOKING = [...interests, ...additional_interests]
    },
    mounted() {
        this.lookingFor = this.payload?.interested_in ?? []
    }
}
</script>
<style lang="scss" scoped>
    .looking-for-article {

        h1 {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #fff;
            margin-bottom: 20px;
        }

        p {
            color: #fff;
        }

        @media(max-width: 480px) {

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .form-group {
                .button--primary {
                    margin-bottom: 0;
                }
            }
        }
    }

    .list {
        margin-bottom: 60px;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }

    .checkbox {
        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        label {
            padding: 0 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #21252D;
            color: #ffffff;
            font-size: 16px;
            height: 36px;
            border-radius: 18px;
            font-family: "Open Sans", sans-serif;
            cursor: pointer;
            transition: all .3s ease;
            background-color: #111317;
        }

        input:checked + label {
            border-color: #B98A56;
            background-color: #292118;
        }
    }
</style>