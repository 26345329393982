<template>
    <section class="steps-signup">
        <article class="body-type-article">
            <div class="header o-flex o-flex--center o-flex--justify">
                <BackButton :callback="backButtonHandler" />
                <router-link :to="{name: 'home'}" class="header-logo">
                    <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                </router-link>

                <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
            </div>
            <h1>{{$t('signup.new_registration.body_type')}}</h1>

            <ul class="list">
                <li v-for="b in bodyTypes" :key="b.code">
                    <div class="checkbox">
                        <input type="radio" v-model="body_type" name="body_type" :id="`body_type${b.code}`" :value="b.value">
                        <label :for="`body_type${b.code}`">
                            {{$t(`form_fields.body_type_options_${b.value}`)}}
                        </label>
                    </div>
                </li>
            </ul>
            <div class="form-group submit-button">
                <button :disabled="working || disableButton" @click="nextStepHandler" class="button button--primary w-100">
                    {{$t('signup.new_registration.continue')}}
                </button>
            </div>
        </article>
    </section>
</template>
<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import LanguageSelection from "@/components/language/LanguageSelection";
import BackButton from "@/components/generic/BackButton";
import { bodyTypes } from "@/service/forms";

export default {
    name: 'YourBodyType',
    mixins: [ThemeMixin],
    components: {LanguageSelection, BackButton},
    emits: ['nextStep', 'previousStep'],
    props: {
        gender: String,
        payload: Object,
        working: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        nextStepHandler() {
            if(this.body_type === null) {
                return
            }
            this.$emit('nextStep', {body_type: this.body_type})
        },
        backButtonHandler() {
            this.$emit('previousStep', {current: 'YourBodyType'})
        },
    },
    data() {
        return {
            theme: '',
            body_type: null
        }
    },
    computed: {
        disableButton() {
            return this.body_type === null
        }
    },
    created() {
        this.theme = process.env.VUE_APP_THEME
        this.bodyTypes = bodyTypes.filter(e => e.genders.includes(1))
    },
    mounted() {

        this.body_type = this.payload?.body_type ?? null

    }
}
</script>
<style lang="scss" scoped>
    .body-type-article {

        h1 {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #fff;
            margin-bottom: 20px;
        }

        @media(max-width: 480px) {

            .form-group {
                .button--primary {
                    margin-bottom: 0;
                }
            }
        }
    }

    .list {
        margin-bottom: 60px;
    }

    .checkbox {
        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        label {
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #21252D;
            color: #ffffff;
            font-size: 18px;
            font-family: "Open Sans", sans-serif;
            cursor: pointer;
            transition: all .3s ease;
        }

        input:checked + label {
            color: #B98A56;
        }
    }
</style>