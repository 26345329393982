<template>
    <div class="o-container">

        <section class="profile" v-if="logged">
            <div class="profile__heading">
                <BackButton />
                <h2>{{ucFirst($t('profile.edit.edit_profile'))}}</h2>
            </div>
            <article class="profile__header">
                <!-- :style="{'background-image': 'url(' + profilePhoto() + ')'}" -->
                <div class="img-wrapper"  @click="uploadModalShow = true">
                    <div :class="{'private':auth.user.photo?.private}" class="img-box">
                        <Image :src="profilePhoto()" />
                    </div>
                    <transition name="fade" mode="out-in">
                        <MediaLoader v-if="uploading"/>
                    </transition>
                    <label class="photo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18"><g data-name="Icon material-photo-camera"><path fill="#000" data-name="Path 58" d="M13.2 10A3.2 3.2 0 1 1 10 6.8a3.2 3.2 0 0 1 3.2 3.2Z"/><path fill="#000" data-name="Path 59" d="M7 0 5.17 2H2a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V4a2.006 2.006 0 0 0-2-2h-3.17L13 0Zm3 15a5 5 0 1 1 5-5 5 5 0 0 1-5 5Z"/></g></svg>
                    </label>
                    <div v-if="auth.user.photo?.approved === 0 && ! uploading" class="pending-overlay">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"><g data-name="Icon feather-clock" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path data-name="Path 65" d="M31.5 16.5a15 15 0 1 1-15-15 15 15 0 0 1 15 15Z"/><path data-name="Path 66" d="M16.5 7.5v9l6 3"/></g></svg>
                        </span>
                        <p>{{$t('file_control.waiting_for_approval')}}!</p>
                    </div>
                </div>
                <div v-if="auth.user.photo?.private" class="secret-profile-photo">
                    <p>{{$t('profile.private_profile_photo_visible')}}</p>
                </div>
                <div class="content-main">
                    <div class="content-main--header">
                        <h1>{{auth.user.username}} <small>({{$t(`profile.edit.${auth.user.gender !== TYPE_GENDER_FEMALE ? 'male' : 'female'}`)}})</small></h1>
                         <router-link :to="{name: 'user', params: {id: auth.user.id}}" class="view-profile">{{ucFirst($t('profile.edit.view_profile'))}}</router-link>
                    </div>
                    <div class="content-main--body">
                        <Progressbar :completed="progressBarPercent"/>
                        <p>{{$t('profile.edit.profile_completion')}} <span>{{progressBarPercent}}%</span></p>
                    </div>

                </div>
            </article>

            <article class="form-box">
                <Form ref="editProfileForm" :validation-schema="updateProfileValidationSchema" @submit="onSubmit">
                    <h4>{{$t('profile.edit.basics')}}</h4>
                    <div class="input-box input-box--search input-box--search-gray">
                        <SearchLocation @location-updated="setLocation" :location="location" :placeholder="$t('form_fields.enter_your_city')" class="input input--secondary" />
                        <Field v-show="false" name="location" v-model="location" />
                        <ErrorMessage class="text-error text-left form-text" name="location"  />
                    </div>
                    <div v-if="auth.user.gender === TYPE_GENDER_MALE">
                        <h4 class="mt+">{{$t('profile.offers')}}</h4>
                        <div class="offer-input-box">
                            <p>{{$t('profile.offer_per_meeting')}}</p>
                            <div class="input-box">
                                <Field inputmode="numeric" name="meeting" :validateOnInput="true" :placeholder="$t('credits.success.amount')  + ` (${auth.user.local_currency.name.toUpperCase()})`" type="number" class="input input--primary" v-model="userData.offers.meeting"/>
                                <ErrorMessage class="text-error text-left form-text" name="meeting"  />
                            </div>
                        </div>
                        <div class="offer-input-box">
                            <p>{{$t('profile.offer_per_weekend')}}</p>
                            <div class="input-box">
                                <Field inputmode="numeric" name="weekend" :validateOnInput="true" :placeholder="$t('credits.success.amount') + ` (${auth.user.local_currency.name.toUpperCase()})`" type="number" class="input input--primary" v-model="userData.offers.weekend"/>
                                <ErrorMessage class="text-error text-left form-text" name="weekend"  />
                            </div>
                        </div>
                        <div class="offer-input-box">
                            <p>{{$t('profile.offer_per_month')}}</p>
                            <div class="input-box">
                                <Field inputmode="numeric" name="month" :validateOnInput="true" :placeholder=" $t('credits.success.amount') + ` (${auth.user.local_currency.name.toUpperCase()})`" type="number" class="input input--primary" v-model="userData.offers.month"/>
                                <ErrorMessage class="text-error text-left form-text" name="month"  />
                            </div>
                        </div>
                    </div>
                    <h4 class="mt+">{{$t('users.physical')}}</h4>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.body_type; profileDataComponent.component = 'OnDemandBodyType'">
                            {{$t('form_fields.body_type_label')}}

                            <div>
                                <span v-if="userData.body_type !== null" class="selected">{{$t(`form_fields.body_type_options_${userData.body_type}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                        <Field as="hidden" name="body_type" :value="userData.body_type" />
                        <ErrorMessage class="text-error text-left form-text" name="body_type" data-vv-as="Body type"  />
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.ethnicity; profileDataComponent.component ='OnDemandEthnicity'">
                            {{$t('form_fields.ethnicity_label')}}

                            <div>
                                <span v-if="userData.ethnicity !== null" class="selected">{{$t(`form_fields.ethnicity_options_${userData.ethnicity}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.hair_color; profileDataComponent.component = 'OnDemandHairColor'">
                            {{$t('form_fields.hair_color_label')}}

                            <div>
                                <span v-if="userData.hair_color !== null" class="selected">{{$t(`form_fields.hair_color_options_${userData.hair_color}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.eye_color; profileDataComponent.component = 'OnDemandEyeColor'">
                            {{$t('form_fields.eye_color_label')}}

                            <div>
                                <span v-if="userData.eye_color !== null" class="selected">{{$t(`form_fields.eye_color_options_${userData.eye_color}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.piercing; profileDataComponent.component = 'OnDemandPiercing'">
                            {{$t('form_fields.piercing_label')}}

                            <div>
                                <span v-if="userData.piercing !== null" class="selected">{{$t(`form_fields.piercing_options_${userData.piercing}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.tattoo; profileDataComponent.component = 'OnDemandTattoo'">
                            {{$t('form_fields.tattoo_label')}}

                            <div>
                                <span v-if="userData.tattoo !== null" class="selected">{{$t(`form_fields.tattoo_options_${userData.tattoo}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="height-box">
                        <div class="input-box">
                            <p class="mb-">{{$t('form_fields.height_label')}}</p>
                            <Field id="height" type="number" class="input input--primary" :placeholder="$t('form_fields.height_placeholder')" :label="$t('form_fields.height_label')" v-model="userData.height" name="height" />
                            <ErrorMessage class="text-error text-left form-text" name="height"  />
                        </div>
                        <div class="unit">cm</div>
                    </div>
                    <h4 class="mt+">{{$t('users.lifestyle')}}</h4>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.smoking; profileDataComponent.component = 'OnDemandSmoking'">
                            {{$t('form_fields.smoking_label')}}

                            <div>
                                <span v-if="userData.smoking !== null" class="selected">{{$t(`form_fields.smoking_options_${userData.smoking}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.drinking; profileDataComponent.component = 'OnDemandDrinking'">
                            {{$t('form_fields.drinking_label')}}

                            <div>
                                <span v-if="userData.drinking !== null" class="selected">{{$t(`form_fields.drinking_options_${userData.drinking}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div v-if="auth.user.gender === TYPE_GENDER_MALE" class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.income; profileDataComponent.component = 'OnDemandYearlyIncome'">
                            {{$t('form_fields.income_label')}}

                            <div>
                                <span v-if="userData.income !== null" class="selected">{{$t(`form_fields.income_options_${userData.income}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div v-if="auth.user.gender === TYPE_GENDER_MALE" class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.net_worth; profileDataComponent.component = 'OnDemandNetWorth'">
                            {{$t('form_fields.net_worth_label')}}

                            <div>
                                <span v-if="userData.net_worth !== null" class="selected">{{$t(`form_fields.net_worth_options_${userData.net_worth}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="input-box">
                        <div class="input-fake" @click="profileDataComponent.value = userData.education; profileDataComponent.component = 'OnDemandEducation'">
                            {{$t('form_fields.education_label')}}

                            <div>
                                <span v-if="userData.education !== null" class="selected">{{$t(`form_fields.education_options_${userData.education}`)}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <h4 class="mt+">{{$t('users.bio')}}</h4>
                    <div class="input-box">
                        <div v-if="auth.user.gender === TYPE_GENDER_FEMALE"  class="input-box--buttons">
                            <div v-for="i in [...profileOptions.interests, ...profileOptions.additional_interests]" :key="i.code" class="checkbox">

                                <Field :id="`interested_${i.code}`" type="checkbox" class="form-check-input" v-model="userData.interested_in" name="interested_in" :value="i.value" />
                                <label :for="`interested_${i.code}`" class="button button--third form-check-label">
                                    <template v-if="i.code === 'selling_pics'">
                                        {{$t(`signup.new_registration.interests.selling_pics.${auth.user.gender === TYPE_GENDER_FEMALE ? 'sb' : 'sd'}`)}}
                                    </template>
                                    <template v-else>
                                        {{$t(`signup.new_registration.interests.${i.code}`)}}
                                    </template>
                                </label>
                            </div>
                            <ErrorMessage class="text-error text-left form-text" name="interested_in"  />
                        </div>
                    </div>
                    <div class="input-box input-box__textarea">
                        <p>{{$t('form_fields.needs_label')}}</p>
                        <Field as="textarea" :placeholder="$t('form_fields.needs_placeholder')" v-model.trim="userData.needs" name="needs" rows="4" class="input input--primary input--textarea"></Field>
                        <ErrorMessage class="text-error text-left form-text" name="needs"  />
                    </div>

                    <div v-if="auth.user.gender !== TYPE_GENDER_FEMALE" class="input-box input-box__textarea">
                        <p>{{$t('form_fields.description_label')}}</p>
                        <Field as="textarea" :placeholder="$t('form_fields.description_placeholder')" v-model.trim="userData.description" name="description" rows="4" class="input input--primary input--textarea"></Field>
                        <ErrorMessage class="text-error text-left form-text" name="description"  />
                    </div>
                    <div v-else class="input-box input-box__textarea">
                        <p>{{$t('form_fields.greeting_label')}}</p>
                        <Field as="textarea" :placeholder="$t('form_fields.greeting_placeholder')" v-model.trim="userData.greeting" name="greeting" rows="4" class="input input--primary input--textarea"></Field>
                        <ErrorMessage class="text-error text-left form-text" name="greeting"  />
                    </div>

                    <div class="info">
                        {{$t('profile.edit.will_be_lost')}} <br>
                        {{$t('profile.edit.site_guidelines')}}
                    </div>

                    <div class="o-flex o-flex--center o-flex--right action-buttons">
                        <button type="button" @click="revert" :disabled="working || !updated" class="button button--third mr- mb0">{{$t('profile.edit.cancel')}}</button>
                        <button :disabled="working || ! updated" class="button button--primary">{{$t('profile.edit.save')}}</button>
                    </div>

                </Form>
            </article>
            <teleport to="#modals">
                <transition name="fade">
                    <component @set="updateUserData" @success="handleSuccess" :value="profileDataComponent.value" @close="profileDataComponent.component = null" v-if="profileDataComponent.component !== null" :is="profileDataComponent.component"></component>
                </transition>
            </teleport>
        </section>
        <transition name="fade">
            <teleport to="#modals">
                <Modal class="modal modal--mob" v-if="uploadModalShow" @close="uploadModalShow = false">
                    <template #body>
                        <UploadProfilePhotoPanel @finish="uploadModalShow = false" />
                    </template>
                </Modal>
            </teleport>
        </transition>

    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
import { required, min_value, max_value, numeric } from '@vee-validate/rules'
import { authenticatedUserPhoto, locationPayload, updateProfile } from '@/service/user'
import SearchLocation from "@/components/location/SearchLocation";
import * as forms from "@/service/forms"
import { mapGetters, mapActions } from "vuex"
import { editableData } from "@/utilities/user"
import toastr from "toastr"
import OnDemandBodyType from "@/components/auth/profile/OnDemandBodyType";
import OnDemandEthnicity from "@/components/auth/profile/OnDemandEthnicity";
import OnDemandHairColor from "@/components/auth/profile/OnDemandHairColor";
import OnDemandEyeColor from "@/components/auth/profile/OnDemandEyeColor";
import OnDemandPiercing from "@/components/auth/profile/OnDemandPiercing";
import OnDemandTattoo from "@/components/auth/profile/OnDemandTattoo";
import OnDemandSmoking from "@/components/auth/profile/OnDemandSmoking";
import OnDemandDrinking from "@/components/auth/profile/OnDemandDrinking";
import OnDemandYearlyIncome from "@/components/auth/profile/OnDemandYearlyIncome";
import OnDemandNetWorth from "@/components/auth/profile/OnDemandNetWorth";
import OnDemandEducation from "@/components/auth/profile/OnDemandEducation";
import MediaLoader from "@/components/MediaLoader";
import Progressbar from "@/components/ProgressBar";
import { TYPE_GENDER_MALE, TYPE_GENDER_FEMALE } from "@/types/user";
import { ucFirst } from "@/utilities/general";
import UploadProfilePhotoPanel from "@/components/photos/UploadProfilePhoto";
import Modal from "@/components/generic/Modal";
import BackButton from "@/components/generic/BackButton";
import SwitchButton from "@/components/generic/SwitchButton";
import Image from "@/components/generic/Image";

export default {
    name: "Profile",
    mixins: [AuthenticatedMixin],
    components: {
        BackButton,
        Modal,
        SearchLocation, Form, Field, ErrorMessage, OnDemandBodyType, OnDemandEthnicity, OnDemandHairColor,
        OnDemandEyeColor, OnDemandPiercing, OnDemandTattoo, OnDemandSmoking, OnDemandDrinking, OnDemandYearlyIncome,
        OnDemandNetWorth, OnDemandEducation, MediaLoader, Progressbar, UploadProfilePhotoPanel, SwitchButton, Image
    },
    data() {
        return {
            working: false,
            uploading: false,
            location: null,
            profileOptions: forms,
            updateProfileValidationSchema: null,
            userData: null,
            progressBarPercent: 55,
            profileDataComponent: {
                value: null,
                component: null,
            },
            uploadModalShow: false,
            askForDetails: [

            ],
        }
    },
    computed: {
        ...mapGetters({
            profilePhoto: 'authenticate/profilePhoto'
        }),
        updated() {
            const original = {}
            const current = {}

            editableData.forEach(field => {
                original[field] = this.auth.user[field]
                current[field] = this.userData[field]
            })
            original.interested_in = original.interested_in.map(e => e.interested)
            current.interested_in = [...current.interested_in]
            current.offers = {
                meeting: current.offers?.meeting ? parseInt(current.offers.meeting) : null,
                weekend: current.offers?.weekend ? parseInt(current.offers.weekend) : null,
                month: current.offers?.month ? parseInt(current.offers.month) : null
            }

            original.offers = {
                meeting: this.auth.user?.offers?.find(e => e.type === 'meeting')?.amount || null,
                weekend: this.auth.user?.offers?.find(e => e.type === 'weekend')?.amount || null,
                month: this.auth.user?.offers?.find(e => e.type === 'month')?.amount || null
            }

            return JSON.stringify(original) !== JSON.stringify(current)
        }
    },
    methods: {

        handleSuccess() {

            this.askForDetails = this.askForDetails.filter(e => e.component !== this.profileDataComponent.component);

            this.$nextTick(() => {
                this.checkMissingProfileInfo()
            })
        },
        ucFirst,
        ...mapActions({
            add: 'profile/add',
        }),
        async updateUserData(value) {

            this.profileDataComponent.component = null

            this.userData = {
                ...this.userData,
                ...value
            }

            await updateProfile(value)

            await this.$nextTick(() => this.checkMissingProfileInfo())

            this.progressBarPercent = this.auth.user.profile_strength

        },
        checkMissingProfileInfo() {

            const data = this.askForDetails.find(e => this.userData[e.field] === null)

            if(data) {
                return this.profileDataComponent.component = data.component
            }

            this.profileDataComponent.component = null

        },
        revert() {
            editableData.forEach(field => this.userData[field] = this.auth.user[field])
            if(this.auth.user.interested_in?.length) {
                this.userData.interested_in = this.auth.user.interested_in.map(e => e.interested)
            }
            this.userData.offers = {
                meeting: this.auth.user?.offers?.find(e => e.type === 'meeting')?.amount || null,
                weekend: this.auth.user?.offers?.find(e => e.type === 'weekend')?.amount || null,
                month: this.auth.user?.offers?.find(e => e.type === 'month')?.amount || null
            }

            this.location = {
                latitude: this.auth.user.latitude,
                longitude: this.auth.user.longitude,
                city: this.auth.user.city,
                location: this.auth.user.address,
                country: this.auth.user.country,
                state: this.auth.user.state,
            }
        },
        setLocation(location) {
            this.location = location

            if(location) {
                this.userData = {
                    ...this.userData,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    city: location.city,
                    address: location.location,
                    country: location.country,
                    state: location.state,
                }
            }

        },
        async onSubmit() {

            this.working = true

            let data = {}

            for(const i in this.userData) {
                if(this.userData[i] !== null && editableData.includes(i) && this.userData[i] !== '')
                    data[i] = this.userData[i]
            }


            if(data.offers.meeting || data.offers.weekend || data.offers.month) {
                data = {
                    ...data,
                    offers: Object.keys(data.offers).filter(e => data.offers[e]).map(e => ({type: e, amount: data.offers[e]}))
                }
            } else {
                data = {
                    ...data,
                    offers: []
                }
            }

            try {
                await updateProfile(data)
                toastr.success(this.$i18n.t('profile.edit.profile_changed'))
                this.progressBarPercent = this.auth.user.profile_strength
            } catch ({response: { data: { errors } }}) {
                this.$refs.editProfileForm.setErrors(errors)
                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            this.working = false


        },
    },
    created() {
        this.TYPE_GENDER_FEMALE = TYPE_GENDER_FEMALE
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
        defineRule('required', required)
        defineRule('min', (value, [min], ctx) => [...value].length < parseInt(min) ? this.$i18n.t('validation.min.string').replace(':attribute', ctx.field).replace(':min', min) : true)
        defineRule('min_value', min_value)
        defineRule('max_value', max_value)
        defineRule('numeric', numeric)

        this.updateProfileValidationSchema = {
            location: 'required',
            needs: 'required|min:10',
            height: 'min_value:130|max_value:230',
        }

        if(this.auth.user.gender === TYPE_GENDER_FEMALE) {
            this.updateProfileValidationSchema.greeting = 'required|min:10'
            this.updateProfileValidationSchema.interested_in = 'required'
        } else {
            this.updateProfileValidationSchema.description = 'required|min:10'
            this.updateProfileValidationSchema.meeting = 'numeric|min_value:50|max_value:100000'
            this.updateProfileValidationSchema.weekend = 'numeric|min_value:50|max_value:100000'
            this.updateProfileValidationSchema.month = 'numeric|min_value:50|max_value:100000'
        }

        this.userData = {
            ...this.auth.user,
            interested_in: this.auth.user.interested_in.map(e => e.interested),
        }

        this.userData = {
            ...this.userData,
            offers: {
                meeting: this.userData?.offers?.find(e => e.type === 'meeting')?.amount || null,
                weekend: this.userData?.offers?.find(e => e.type === 'weekend')?.amount || null,
                month: this.userData?.offers?.find(e => e.type === 'month')?.amount || null
            }
        }

        this.location = locationPayload(this.auth.user)

    },

    mounted() {
        this.photo = authenticatedUserPhoto(this.auth.user)
        this.progressBarPercent = this.auth.user.profile_strength

        let data = [
            { field: 'body_type', component: 'OnDemandBodyType' },
            { field: 'ethnicity', component: 'OnDemandEthnicity' },
            { field: 'hair_color', component: 'OnDemandHairColor' },
            { field: 'eye_color', component: 'OnDemandEyeColor' },
            { field: 'piercing', component: 'OnDemandPiercing' },
            { field: 'tattoo', component: 'OnDemandTattoo' },
            { field: 'smoking', component: 'OnDemandSmoking' },
            { field: 'drinking', component: 'OnDemandDrinking' },
            { field: 'income', component: 'OnDemandYearlyIncome' },
            { field: 'net_worth', component: 'OnDemandNetWorth' },
            { field: 'education', component: 'OnDemandEducation' },
        ]

        if(this.auth.user.gender === TYPE_GENDER_FEMALE) {
            data = data.filter(e => ! ['income', 'net_worth'].includes(e.field))
        }

        this.askForDetails = data

        this.checkMissingProfileInfo()
    },
}
</script>

<style scoped lang="scss">

    .private-photo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }

    .pending-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-type18, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        flex-direction: column;
        color: $color-type1;

        p {
            max-width: 100px;
            margin: 0 auto;
            text-align: center;
            line-height: 1.3;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color-type18, .6);
            width: 60px;
            height: 60px;
            border-radius: 10px;
            margin-bottom: 15px;
        }
    }

    .profile__heading {
        margin-bottom: 20px;
        grid-column: 1 / span 3;

        .back-mob {
            display: none;
        }

         h2 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 10px;
            letter-spacing: -0.9px;
        }

        @media(max-width: 991px) {
            display: flex;
            align-items: center;

            h2 {
                margin-bottom: 0;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
                background-color: transparent;
            }
        }
    }
    .text-error {
        color: $color-type24;
        font-size: small;
    }

    .offer-input-box {
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;

        p {
            margin-bottom: 0;
        }

        .text-error {
            white-space: nowrap;
        }
    }

    .height-box {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        margin-top: 20px;

        .input-box {
            margin-bottom: 0;
            flex: 1;
        }

        .unit {
            flex:0 0 30px;
            margin-left: 20px;
            margin-bottom: 13px;
        }
    }

    .input-box {
        margin-bottom: 0;
    }

    .input-box__textarea {
        margin-bottom: 20px;
    }

    .input-fake {
        border-bottom: 1px solid $color-type5;
        color: $color-type18;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        cursor: pointer;

        .selected {
            height: 26px;
            border-radius: 13px;
            padding: 0 10px;
            font-size: 16px;
            color: $color-type1;
            background-color: $color-type18;
            margin-right: 22px;
        }
    }

    .info {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 13px;
        color: $color-type9;
        line-height: 1.6;
    }

    .row {
        margin-left: -10px;
        margin-right: -10px;

        >div {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .content-main--body {
        p {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    .profile {
        padding: 60px 100px;
        background-color: $color-type1;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 230px 1fr;
        column-gap: 40px;
        margin: 0 auto;

        @media(max-width: 1200px) {
            padding: 30px 50px;
            max-width: 930px;
        }

        @media(max-width: 991px) {
            box-shadow: none;
            padding: 20px 0 70px;
            margin-top: 0;
            display: block;
            border-radius: 0;
            background-color: transparent;
        }
    }

    .input-box--buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .form-check-input:checked + .button--third {
            background-color: $color-type18;
            color: $color-type1;
            border-color: $color-type18;
        }
    }

    .button--third {
        border: 1px solid rgb(239, 239, 239);
        padding: 0 30px;
        margin-right: 3px;
        margin-bottom: 6px;
    }

    .content-main {
        width: 100%;
    }

    .content-main--header {
        margin-bottom: 20px;
        width: 100%;

        h1 {
            margin-bottom: 0;
            font-size: 18px;

            small {
                margin-left: 3px;
                color: $color-type9;
                font-size: 18px;
                font-weight: 400;
            }
        }


        .view-profile {
            color: $color-type8;
            font-weight: 700;
            font-size: 18px;
            margin-top: 2px;
            letter-spacing: -0.74px;
        }

        @media(max-width: 991px) {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            h1 {
                font-size: 18px;
                font-weight: 400;
            }

        }
    }

    .profile__header {

        .img-wrapper {
            width: 100%;
            aspect-ratio: 1 / 1.3;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            margin-bottom: 30px;

            .photo {
                position: absolute;
                right: 15px;
                bottom: 0;
                transform: translate(0,50%);
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: $color-type1;
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .img-box {
                overflow: hidden;
                height: 100%;
                width: 100%;

                &.private {
                    img {
                        filter: blur(25px) brightness(70%);
                    }
                }
            }

            img {
                object-fit: cover;
                height: 100%;
            }
        }

        @media(max-width: 991px) {
            display: block;

            .img-wrapper {
                margin: 0 auto 30px;
                min-width: 170px;
                width: 170px;
                height: auto;
            }

        }
    }

    @media(max-width: 500px) {
        .action-buttons {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 999;
            background-color: $color-type2;
            padding: 10px 20px;

            .button {
                flex: 0 1 calc(50% - 10px);
            }
        }
    }

</style>
