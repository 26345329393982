<template>
    <div class="steps-signup">
        <article class="register-form" :class="gender === 'woman'? 'registe-form-woman':''">
            <Form ref="registrationForm" :validation-schema="validationSchema" @submit="onSubmit" @keydown.enter="$event.preventDefault()" autocomplete="off">
                <div class="header o-flex o-flex--center o-flex--justify">
                    <router-link :to="{name: 'home'}" class="header-logo">
                        <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                    </router-link>

                    <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
                </div>

                <template v-if="gender === 'man'">
                    <h1>{{$t('signup.new_registration.sign_up_sd').split('Sugar daddy')[0]}}<br> <strong>Sugar daddy</strong></h1>
                    <h2>{{$t('signup.new_registration.product_built')}}</h2>
                </template>

                <template v-else>
                    <h1>{{$t('signup.new_registration.sign_up_sb').split('Sugar baby')[0]}}<br> <strong>Sugar baby</strong></h1>
                    <h2>{{$t('signup.new_registration.tailored')}}</h2>
                </template>


                <div class="step">
                    <div class="input-box">
                        <div class="relative">
                            <Field  :placeholder="$t('form_fields.username_label')" class="input input--primary" tabindex="1" type="text" name="username" />
                            <span class="placeholder">{{$t('form_fields.username_label')}}</span>
                        </div>
                        <ErrorMessage class="text-error text-left form-text" name="username"  />
                    </div>
                    <div class="input-box">
                        <div class="relative">
                            <Field class="input input--primary" :placeholder="$t('form_fields.email_label')" tabindex="2" name="email" />
                            <span class="placeholder">{{$t('form_fields.email_label')}}</span>
                        </div>
                        <ErrorMessage class="text-error text-left form-text" name="email"  />
                    </div>
                    <div class="input-box">
                        <div class="relative">
                            <a href="javascript:;" @click="showPass = !showPass" class="show-password">
                                <svg v-if="showPass" width="22" viewBox="0 0 22.15 22">
                                    <g fill="none" data-name="Group 1529">
                                        <path d="M.08 0h22v22h-22z" data-name="Rectangle 657"/>
                                        <g stroke="#6e737e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-name="Icon akar-eye-open">
                                        <path d="M20.7 9.92a1.76 1.76 0 0 1 0 2.16c-1.54 2.03-5.27 6.2-9.62 6.2S3 14.1 1.45 12.08a1.76 1.76 0 0 1 0-2.16c1.55-2.03 5.28-6.2 9.63-6.2s8.08 4.17 9.63 6.2Z" data-name="Path 12079"/>
                                        <path d="M14.2 11a3.12 3.12 0 1 1-3.12-3.12A3.12 3.12 0 0 1 14.2 11Z" data-name="Path 12080"/>
                                        </g>
                                    </g>
                                </svg>
                                <svg v-else width="22" viewBox="0 0 22.16 22">
                                    <g fill="none" data-name="Group 1528">
                                        <path d="M.08 0h22v22h-22z" data-name="Rectangle 658"/>
                                        <g stroke="#6e737e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-name="Icon akar-eye-slashed">
                                        <path d="M5.75 16.34a21.07 21.07 0 0 1-4.3-4.26 1.76 1.76 0 0 1 0-2.16c1.55-2.03 5.28-6.2 9.63-6.2a9.36 9.36 0 0 1 5.34 1.95" data-name="Path 12081"/>
                                        <path d="M13.3 8.8a3.12 3.12 0 1 0-4.42 4.41m-6.12 6.11L19.4 2.68M9 17.98a7.41 7.41 0 0 0 2.08.3c4.35 0 8.08-4.17 9.63-6.2a1.76 1.76 0 0 0 0-2.16 23.86 23.86 0 0 0-1.75-2.04" data-name="Path 12082"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                            <Field :class="`input input--primary`" :placeholder="$t('form_fields.password_label')" tabindex="3" name="password" :type="showPass? 'text':'password'" />
                            <span class="placeholder">{{$t('form_fields.password_label')}}</span>
                        </div>
                        <ErrorMessage class="text-error text-left form-text" name="password"  />
                    </div>

                    <div class="input-box input-box--search input-box--search-gray">
                        <div class="relative">
                            <SearchLocation :placeholder="$t('form_fields.enter_your_city')" :class="`input input--primary`" :location="registration.location" :index="4" @location-updated="getLocation" :pinIcon="false" />
                            <Field :class="`input input--primary`" v-show="false" name="location" v-model="registration.location" />
                        </div>
                        <ErrorMessage class="text-error text-left form-text" name="location"  />
                    </div>
                </div>
                <div class="form-group submit-button">
                    <button type="submit" :disabled="working" tabindex="5" class="button button--primary w-100">
                        <span>{{$t(`signup.new_registration.register_s${gender === 'man' ? 'd' : 'b'}`)}}</span>
                    </button>
                    <p class="confirm-text clr-white" v-html="$t('signup.confirm')"></p>
                    <div class="u-text-center have-account">
                        <p class="return">{{$t('signup.have_account')}} <router-link :to="{name: 'login'}" class="link clr-gold">{{$t('signup.login_here')}}</router-link></p>
                    </div>
                </div>
            </Form>
        </article>
    </div>
</template>
<script>
import SearchLocation from "@/components/location/SearchLocation";
import { Form, Field, ErrorMessage } from 'vee-validate'
import { defineRule } from 'vee-validate';
import { required, email, min, min_value, max, max_value } from '@vee-validate/rules'
import ThemeMixin from "@/mixins/ThemeMixin";
import { mapActions } from "vuex";
import { ucFirst, capitalize } from "@/utilities/general";
import LanguageSelection from "@/components/language/LanguageSelection";

export default {
    name: "SignupForm",
    mixins: [ThemeMixin],
    components: {SearchLocation, Form, Field, ErrorMessage, LanguageSelection},
    props: {
        gender: String,
        payload: {
            type: Object,
            default: null
        },
        errors: {
            type: Object,
            default: null
        },
        working: {
            type: Boolean,
            default: false
        }
    },
    emits: ['nextStep', 'previousStep'],
    data() {
        return {
            theme: '',
            validationSchema: null,
            errorBag: null,
            showPass: false,
            registration: {
                location: null
            },
        }
    },
    methods: {
        ucFirst,
        capitalize,
        ...mapActions({
            setBootstrapping: 'application/setBootstrapping'
        }),
        async onSubmit(values) {
            this.$emit('nextStep', values)
        },
        getLocation(payload) {
            this.registration.location = payload
        }
    },
    created() {
        defineRule('required', required)
        defineRule('email', email)
        defineRule('min', min)
        defineRule('max', max)
        defineRule('min_value', min_value)
        defineRule('max_value', max_value)

        this.validationSchema = {
            email: 'required|email',
            username: 'required|min:3|max:15',
            password: 'required|min:6|max:45',
            location: 'required'
        }
        this.theme = process.env.VUE_APP_THEME
    },
    mounted() {

        this.$refs.registrationForm.setErrors(this.errors)

        if(this.payload.username) {
            this.$refs.registrationForm.setFieldValue('username', this.payload.username)
        }

        if(this.payload.email) {
            this.$refs.registrationForm.setFieldValue('email', this.payload.email)
        }

        if(this.payload.password) {
            this.$refs.registrationForm.setFieldValue('password', this.payload.password)
            this.registration.password = this.payload.password
        }

        if(this.payload?.location) {
            this.registration.location = this.payload.location
        }

    }
}
</script>
<style lang="scss" scoped>

    .header {
        margin-bottom: 80px;
    }

    .header-logo {
        display: inline-block;
        margin-bottom: 0!important;

        @media(max-width: 480px) {
            width: 85px;

            img {
                width: 100%;
            }
        }
    }

    .register-form {

        width: 100%;
        max-width: 460px;
        text-align: left;
        margin: 0 auto;
        padding: 20px 25px 0;
        background-image: url('~@/assets/img/new-img/sign-up-bgr.png');
        background-repeat: no-repeat;
        background-position: 100% 0;

        &.registe-form-woman {
            background-image: url('~@/assets/img/new-img/sign-up-bgr-woman.png');

        }

        h1 {
            color: #FFFFFF;
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 5px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;

            strong {
                color: #B98A56;
                font-weight: 800;
            }
        }

        h2 {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            margin-bottom: 40px;
            font-family: 'Poppins', sans-serif;
        }

        .show-password {
            position: absolute;
            right: 20px;
            top: 14px;
        }

        .have-account {
            margin-top: 15px;
            

            p {
                color: #FFFFFF;
                font-size: 16px;
            }

            a {
                color: #FFFFFF;
                font-size: 16px;

                &:hover {
                    color: #B98A56;
                }
            }
        }

        @media(max-width: 480px) {
            padding: 15px ;

            form {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .have-account {
                    margin-top: 0;
                }
            }
        }
    }


</style>

<style lang="scss">
.register-form {

    .back-mob {
        svg {
            width: 17px;
            height: auto;
            path {
                fill: #fff;
            }
        }
    }

    .confirm-text {
        font-size: 13px;
        line-height: 1.3;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        margin-top: 15px;

        a {
            font-size: 13px;
        }
    }

    .search-box {
        // &.is-active {
        //     input {
        //         border-top-left-radius: 0;
        //         border-top-right-radius: 0;
        //         border-bottom-left-radius: 25px;
        //         border-bottom-right-radius: 25px;
        //     }

        //     .autocomplete-dropdown {
        //         border-top-left-radius: 5px;
        //         border-top-right-radius: 5px;

        //         border-bottom-left-radius: 0;
        //         border-bottom-right-radius: 0;
        //     }
        // }
    }
    
    .autocomplete-dropdown {
        background-color: #1A1D23!important;
        // top: auto;
        // bottom: 100%;
        
        .autocomplete-dropdown__item,
        .current-location p {
            font-size: 14px!important;
        }

        .current-location {
            display: none;
        }

        .current-location p {
            margin-bottom: 10px;
        }

        h3, p {
            color: #fff!important;
            svg {
                path {
                    fill: #fff!important;
                }
            }

        }

        .autocomplete-dropdown__item {
            color: #fff!important;

            svg {
                path {
                    fill: #fff!important;
                }
            }

            &:hover {
                color: #B98A56!important;

                svg {
                    path {
                        fill: #B98A56!important;
                    }
                }
            }
        }

        .autocomplete-dropdown__results {
            margin-top: 0!important;
        }
    }
}
</style>