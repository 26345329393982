<template>
    <section class="register">
        <div class="o-container">
            <ContentLoader class="content-loader-white" v-if="working"/>
            <component v-else :payload="formData" :working="working" :gender="gender" :errors="errors" :is="currentComponent" @nextStep="stepHandler" @previousStep="previousStepHandler"></component>
        </div>
    </section>
</template>
<script>
import LookingFor from '@/components/auth/steps/LookingFor.vue';
import SignupForm from '@/components/auth/steps/SignupForm.vue';
import YourAge from '@/components/auth/steps/YourAge.vue';
import YourBodyType from '@/components/auth/steps/YourBodyType.vue';
import YourEthnicity from '@/components/auth/steps/YourEthnicity.vue';
import SeekInPartner from '@/components/auth/steps/SeekInPartner.vue';
import YourOffer from '@/components/auth/steps/YourOffer.vue';
import {loadMarketingParams} from "@/service/marketing";
import {login, register} from "@/api/auth";
import {recaptcha} from "@/service/recaptcha";
import {pushDataLayer} from "@/service/gtm";
import {TYPE_GENDER_MALE} from "@/types/user";
import {GTM_EVENT_TYPE_SUGAR_BABY_SIGNUP_SOI, GTM_EVENT_TYPE_SUGAR_DADDY_SIGNUP_SOI} from "@/service/gtm/types";
import {bootUser} from "@/service";
import {mapActions} from "vuex";
import ContentLoader from '../ContentLoader.vue';
export default {
    name: "RegisterNew",
    components: {SignupForm, YourAge, YourEthnicity, YourBodyType, SeekInPartner, YourOffer, LookingFor, ContentLoader},
    props: {
        gender: String,
    },
    data() {
        return {
            currentComponent: 'SignupForm',
            formData: {},
            errors: {},
            steps: [],
            working: false
        }
    },
    methods: {
        ...mapActions({
            setBootstrapping: 'application/setBootstrapping'
        }),
        stepHandler(payload) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 100);

            let index = this.steps.indexOf(this.currentComponent)

            this.formData = {
                ...this.formData,
                ...payload
            }

            if(index + 1 < this.steps.length) {
                return this.currentComponent = this.steps[++index]
            }


            this.registrationHandler()

        },
        previousStepHandler(event) {

            if(this.steps.indexOf(event.current) !== -1) {
                this.currentComponent = this.steps[this.steps.indexOf(event.current) - 1]
            }

        },
        errorStepHandler(errors) {

            const steps = [
                {
                    component: 'SignupForm',
                    fields: ['username', 'email', 'password', 'location'],
                },
                {
                    component: 'YourAge',
                    fields: ['age'],
                },
                {
                    component: 'SeekInPartner',
                    fields: ['needs'],
                },
                {
                    component: 'YourEthnicity',
                    fields: ['ethnicity'],
                },
                {
                    component: 'YourOffer',
                    fields: ['interested_in'],
                },
                {
                    component: 'YourEthnicity',
                    fields: ['ethnicity'],
                }
            ]


            for(let i = 0; i < steps.length; i++) {
                let found = false
                for (let j = 0; j < steps[i].fields.length; j++) {
                    if(Object.hasOwn(errors, steps[i].fields[j])) {
                        console.log(steps[i].fields[j])
                        found = true
                        this.currentComponent = steps[i].component
                        break
                    }
                }
                if(found) {
                    break
                }
            }
        },
        async registrationHandler() {

            this.working = true;

            let payload = {
                ...this.formData
            }

            payload.gender   = this.gender === 'man' ? 0 : 1
            payload.lang     = this.$store.state.authenticate.lang

            for(const property in this.formData.location) {
                payload[property] = this.formData.location[property]
            }

            payload.address = this.formData.location.location

            const marketing = loadMarketingParams()

            if(marketing) {

                payload = {
                    ...payload,
                    ...marketing,
                }

            }

            payload.token = await recaptcha()

            try {
                const response = await register(payload)

                pushDataLayer(
                    payload.gender === TYPE_GENDER_MALE ? GTM_EVENT_TYPE_SUGAR_DADDY_SIGNUP_SOI : GTM_EVENT_TYPE_SUGAR_BABY_SIGNUP_SOI,
                    response.data
                )

                localStorage.removeItem('marketing')

                let { data: {access_token} } = await login(payload.email, payload.password)

                localStorage.setItem('auth_token', access_token);

                await this.setBootstrapping(true)

                await bootUser(false)

                await this.$router.push('search')

                await this.setBootstrapping(false)

            } catch (e) {

                 if(e.response && e.response.status === 422) {
                     this.errors = e.response.data.errors
                     this.errorStepHandler(this.errors)
                 }
            }

            this.working = false

        }
    },
    created() {

        this.steps = [
            ...['SignupForm', 'YourAge', 'YourEthnicity'],
        ]

        if(this.gender === 'man') {
            return this.steps = [
                ...this.steps,
                ...['SeekInPartner', 'YourOffer']
            ]
        }

        this.steps = [
            ...this.steps,
            ...['YourBodyType', 'LookingFor']
        ]

    }
}
</script>

<style scoped lang="scss">
    .register {
        padding: 80px 0;
        min-height: 100dvh;
        background-color: #111317;

        @media(max-width: 480px) {
            padding: 0;

            .o-container {
                padding: 0;
                height: 100%;
            }
        }
    }
</style>

<style lang="scss">
    .content-loader-white {
        .circle{
            background-color: #fff!important;

            &:before {
                background-color: #fff!important;
            }
        }
    }
</style>