<template>
    <section class="steps-signup">
        <article class="ethnicity-article">
            <div class="header o-flex o-flex--center o-flex--justify">
                <BackButton :callback="backButtonHandler" />
                <router-link :to="{name: 'home'}" class="header-logo">
                    <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                </router-link>

                <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
            </div>
            <h1>{{$t('signup.new_registration.ethnicity')}}</h1>
            <ul class="list">
                <li v-for="(e, key) in ethnicities" :key="e.code">
                    <div class="checkbox">
                        <input type="radio" name='ethnicity' :id="`ethnicity+${key}`" :value="e.value" v-model="ethnicity">
                        <label :for="`ethnicity+${key}`">
                            {{$t(`form_fields.ethnicity_options_${e.value}`)}}
                        </label>
                    </div>
                </li>
            </ul>
            <div class="form-group submit-button">
                <button @click="nextStep" :disabled="working || disableButton" class="button button--primary w-100">
                    {{$t('signup.new_registration.continue')}}
                </button>
            </div>
        </article>
    </section>
</template>
<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import {ethnicities} from "@/service/forms";
import LanguageSelection from "@/components/language/LanguageSelection";
import BackButton from "@/components/generic/BackButton";

export default {
    name: 'YourEthnicity',
    mixins: [ThemeMixin],
    components: {LanguageSelection, BackButton},
    emits: ['nextStep', 'previousStep'],
    props: {
        payload: Object,
        working: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            theme: '',
            ethnicity: null
        }
    },
    methods: {
        nextStep() {
            if(this.ethnicities.find(i => i.value === this.ethnicity)) {
                this.$emit('nextStep', {ethnicity: this.ethnicity})
            }
        },
        backButtonHandler() {
            this.$emit('previousStep', {current: 'YourEthnicity'})
        },
    },
    computed: {
        disableButton() {
            return this.ethnicity === null
        }
    },
    created() {
        this.theme = process.env.VUE_APP_THEME
        this.ethnicities = ethnicities
    },
    mounted() {
        if(this.ethnicities.find(i => i.value === this.payload.ethnicity)) {
            this.ethnicity = this.payload.ethnicity
        }
    }
}
</script>
<style lang="scss" scoped>
    .ethnicity-article {

        h1 {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #fff;
            margin-bottom: 20px;
        }

        @media(max-width: 480px) {

            .form-group {
                .button--primary {
                    margin-bottom: 0;
                }
            }
        }
    }

    .list {
        margin-bottom: 60px;
    }

    .checkbox {
        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        label {
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #21252D;
            color: #ffffff;
            font-size: 18px;
            font-family: "Open Sans", sans-serif;
            cursor: pointer;
            transition: all .3s ease;
        }

        input:checked + label {
            color: #B98A56;
        }
    }
</style>