<template>
    <section class="steps-signup">
        <article class="offer-article">
            <Form ref="registrationForm" :validation-schema="validationSchema" @submit="onSubmit" @keydown.enter="$event.preventDefault()" autocomplete="off">
                <div class="header o-flex o-flex--center o-flex--justify">
                    <BackButton :callback="backButtonHandler" />
                    <router-link :to="{name: 'home'}" class="header-logo">
                        <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                    </router-link>

                    <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
                </div>
                <h1>{{$t('signup.new_registration.seek')}}</h1>
                <p class="mb+">{{$t('signup.new_registration.relationship')}}</p>
                <div class="input-box">
                    <div class="relative">
                        <Field :class="`input input--primary input--textarea`" placeholder="Your preferred relationship?" :validateOnInput="true" v-model="needs" name="needs" as="textarea" />
                        <span class="placeholder">{{$t('signup.new_registration.preferred')}}</span>
                    </div>
                    <ErrorMessage class="text-error text-left form-text" name="needs"/>
                </div>

                <div class="form-group submit-button">
                    <button type="submit" :disabled="disableButton" class="button button--primary w-100">
                        {{$t('signup.new_registration.continue')}}
                    </button>
                </div>
            </Form>
        </article>
    </section>
</template>
<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import { defineRule } from 'vee-validate';
import { required, min } from '@vee-validate/rules'
import { Form, Field, ErrorMessage } from 'vee-validate';
import LanguageSelection from "@/components/language/LanguageSelection";
import BackButton from "@/components/generic/BackButton";

export default {
    name: 'SeekInPartner',
    mixins: [ThemeMixin],
    components: { LanguageSelection, BackButton, Form, Field, ErrorMessage},
    props: {
        gender: String,
        payload: Object,
        errors: Object
    },
    emits: ['nextStep', 'previousStep'],
    data() {
        return {
            theme: '',
            needs: ''
        }
    },

    computed: {
        disableButton() {
            return this.needs.length < 6;
        }
    },

    methods: {
        onSubmit() {
            this.$emit('nextStep', {needs: this.needs})
        },
        backButtonHandler() {
            this.$emit('previousStep', {current: 'SeekInPartner'})
        },
    },

    created() {
        defineRule('required', required)
        defineRule('min', min)
        this.validationSchema = {
            needs: 'required|min:6',
        }
        this.theme = process.env.VUE_APP_THEME;
    },
    mounted() {
        this.$refs.registrationForm.setErrors(this.errors);

        if (Object.hasOwn(this.payload, 'needs')) {
            this.needs = this.payload.needs;
        }
    }
}
</script>
<style lang="scss" scoped>
    .offer-article {

        h1 {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #fff;
            margin-bottom: 20px;
        }

        h2 {
            color: #fff;
            text-align: left;
            font-weight: 400;
        }

        p {
            color: #fff;
        }

        .button {
            margin-top: 40px;
        }

        @media(max-width: 480px) {

            .form-group {
                .button--primary {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>