<template>
    <div class="steps-signup">
        <article class="age-article">
            <div class="header o-flex o-flex--center o-flex--justify">
                <BackButton :callback="backButtonHandler" />
                <router-link :to="{name: 'home'}" class="header-logo">
                    <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                </router-link>

                <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
            </div>
            <h1 :class="gender === 'man'? 'mb-': 'mb+'">{{$t('signup.new_registration.your_age')}}</h1>
            <p v-if="gender === 'man'">{{$t('signup.new_registration.tell_sb')}}</p>
            <div class="slider-container"
                @wheel="onWheel"
                @touchstart="onTouchStart"
                @touchmove="onTouchMove"
                @touchend="onTouchEnd"
                @mousedown="onMouseDown"
            >

                <button class="arrow-btn mb-" @click="prev" :disabled="currentIndex === 0">
                    <svg width="18" viewBox="0 0 18 18">
                        <path fill="#fefefe" d="m.58 10.66 7.1-7.09a1.96 1.96 0 0 1 2.72-.06l.01.02 7.01 6.98a1.99 1.99 0 0 1 .58 1.4 1.96 1.96 0 0 1-3.35 1.38L9 7.73l-5.64 5.71a1.96 1.96 0 1 1-2.78-2.77Z" data-name="Union 18"/>
                        <path fill="none" d="M18 0v18H0V0z" data-name="Rectangle 365"/>
                    </svg>
                </button>

                <div class="slider-window">
                    <div class="slider-items" :style="{ transform: `translateY(calc(-${currentIndex * 80}px))` }">
                        <div
                            v-for="(item, index) in ages"
                            :key="index"
                            class="slider-item"
                            :class="{ active: index === currentIndex }"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>

                <button class="arrow-btn mt-" @click="next" :disabled="currentIndex >= ages.length - 1">
                    <svg width="18" viewBox="0 0 18 18" style="transform: rotate(180deg)">
                        <path fill="#fefefe" d="m.58 10.66 7.1-7.09a1.96 1.96 0 0 1 2.72-.06l.01.02 7.01 6.98a1.99 1.99 0 0 1 .58 1.4 1.96 1.96 0 0 1-3.35 1.38L9 7.73l-5.64 5.71a1.96 1.96 0 1 1-2.78-2.77Z" data-name="Union 18"/>
                        <path fill="none" d="M18 0v18H0V0z" data-name="Rectangle 365"/>
                    </svg>
                </button>
            </div>

            <div class="submit-button">
                <button @click="this.$emit('nextStep', {age: ages[currentIndex]})" :disabled="working" class="button button--primary w-100">
                    {{$t('signup.new_registration.continue')}}
                </button>
            </div>
        </article>
    </div>
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import LanguageSelection from "@/components/language/LanguageSelection";
import BackButton from "@/components/generic/BackButton";

export default {
    name: 'YourAge',
    mixins: [ThemeMixin],
    components: {LanguageSelection, BackButton},
    emits: ['nextStep', 'previousStep'],
    props: {
        payload: Object,
        gender: String,
        working: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            theme: '',
            ages: [],
            currentIndex: 1,
            mouseStartY: 0,
            mouseEndY: 0,
            mouseStartTime: 0,
            mouseDeltaY: 0,
            touchStartY: 0,
            touchDeltaY: 0,
            isTouching: false
        };
    },
    methods: {
        next() {
            if (this.currentIndex < this.ages.length - 1) {
                this.scrollToIndex(this.currentIndex + 1);
            }
        },
        prev() {
            if (this.currentIndex > 0) {
                this.scrollToIndex(this.currentIndex - 1);
            }
        },
        backButtonHandler() {
            this.$emit('previousStep', {current: 'YourAge'})
        },
        scrollToIndex(targetIndex) {
            const duration = 300;
            const startIndex = this.currentIndex;
            const distance = targetIndex - startIndex;
            const startTime = performance.now();

            const animateScroll = (currentTime) => {
                const elapsed = currentTime - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easedProgress =
                    progress < 0.5
                        ? 2 * progress * progress
                        : -1 + (4 - 2 * progress) * progress;

                this.currentIndex = Math.round(startIndex + easedProgress * distance);

                if (progress < 1) {
                    requestAnimationFrame(animateScroll);
                } else {

                    this.currentIndex = targetIndex;
                }
            };


            requestAnimationFrame(animateScroll);
        },
        onWheel(event) {
            event.preventDefault();
            const direction = event.deltaY > 0 ? 1 : -1;
            const scrollSpeedFactor = 0.05;
            const scrollDistance = Math.abs(event.deltaY) * scrollSpeedFactor;
            const targetIndex = this.currentIndex + direction * Math.round(scrollDistance);
            if (targetIndex >= 0 && targetIndex < this.ages.length) {
                this.scrollToIndex(targetIndex);
            }
        },
        onMouseDown(event) {
            this.mouseStartY = event.clientY;
            this.mouseStartTime = event.timeStamp;
            window.addEventListener("mousemove", this.onMouseMove);
            window.addEventListener("mouseup", this.onMouseUp);
        },
        onMouseMove(event) {
            const currentY = event.clientY;
            const deltaY = currentY - this.mouseStartY;
            this.mouseDeltaY = deltaY;
            const sensitivity = 40;
            const deltaIndex = Math.floor(Math.abs(deltaY) / sensitivity);
            const newIndex = this.currentIndex - Math.sign(deltaY) * deltaIndex;
            this.currentIndex = Math.min(Math.max(newIndex, 0), this.ages.length - 1);
            if (deltaIndex !== 0) {
                this.mouseStartY = currentY;
            }
        },

        onMouseUp(event) {
            const swipeDuration = event.timeStamp - this.mouseStartTime;
            const swipeDistance = this.mouseDeltaY;

            if (swipeDuration < 100) {
                const extraScroll = Math.min(Math.ceil(Math.abs(swipeDistance) / 20), 3);
                const targetIndex =
                    swipeDistance < 0
                        ? Math.min(this.currentIndex + extraScroll, this.ages.length - 1)
                        : Math.max(this.currentIndex - extraScroll, 0);

                this.scrollToIndex(targetIndex);
            } else {
                this.scrollToIndex(this.currentIndex);
            }
            window.removeEventListener("mousemove", this.onMouseMove);
            window.removeEventListener("mouseup", this.onMouseUp);
            this.mouseDeltaY = 0;
        },

        onTouchStart(event) {
            this.touchStartY = event.touches[0].clientY;
            this.isTouching = true;
        },

        onTouchMove(event) {
        if (!this.isTouching) return;
        const touchMoveY = event.touches[0].clientY;
        const touchDeltaY = this.touchStartY - touchMoveY;
        const sensitivity = 40;
        const deltaIndex = Math.floor(Math.abs(touchDeltaY) / sensitivity);
        if (deltaIndex !== 0) {
            const newIndex = this.currentIndex + Math.sign(touchDeltaY) * deltaIndex;
            this.currentIndex = Math.min(Math.max(newIndex, 0), this.ages.length - 1);
            this.touchStartY = touchMoveY;
        }
        },

        onTouchEnd() {
            if (this.isTouching) {
                this.isTouching = false;
                this.scrollToIndex(this.currentIndex);
            }
        }
    },
    created() {
        this.theme = process.env.VUE_APP_THEME;
        this.ages = Array.from({ length: 100 - 18 + 1 }, (v, i) => ({
            age: i + 18,
        })).map(i => i.age)
    },
    mounted() {
        this.currentIndex = this.ages.indexOf(this.payload?.age ?? 25)
    }
};
</script>

<style lang="scss" scoped>
.age-article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    article {
        height: 100%;
    }

    h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: #fff;
    }

    p {
        color: #fff;
        margin-bottom: 40px;
    }
}

.slider-container {
    position: relative;
    overflow: hidden;
    touch-action: none;
    // height: 300px; /* Adjust as needed */
    margin-bottom: 60px;
}

.arrow-btn {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.arrow-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.slider-window {
    position: relative;
    height: 240px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &:before {
        content:"";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        width: 100px;
        height: 82px;
        z-index: 2;
        background: linear-gradient(180deg, rgba(17,19,23,0.93) 30%, rgba(0,0,0,0) 100%);
        pointer-events: none;
    }
    &:after {
        content:"";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,0);
        width: 100px;
        height: 82px;
        z-index: 2;
        background: linear-gradient(360deg, rgba(17,19,23,0.93) 20%, rgba(0,0,0,0) 100%);
        pointer-events: none;
    }
}

.slider-items {
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    margin-top: 80px;
}

.slider-item {
    height: 80px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    font-weight: 400;
    color: #616161;
    transition: color 0.3s, transform 0.3s;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slider-item.active {
    color: white;
}
</style>
