<template>
    <a @click="goBack" class="back-mob">
        <slot name="content">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001" viewBox="0 0 14.004 14.004"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg>
        </slot>
    </a>
</template>

<script>
import store from "@/store";

export default {
    name: "BackButton",
    props: {
        path: {
            type: String,
            default: "/search"
        },
        callback: {
            type: Function,
            default: null
        }
    },
    methods: {
        async goBack() {

            if(typeof this.callback === "function") {
                return this.callback()
            }

            if(store.state.application.mobile && this.$route.fullPath === '/messages') {
                return await this.$router.push(this.path)
            }

            if(window.history.length > 2) {
                return await this.$router.back()
            }

            await this.$router.push(this.path)

        }
    }
}
</script>