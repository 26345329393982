<template>
    <section class="steps-signup">
        <article class="offer-article">
            <Form ref="registrationForm" :validation-schema="validationSchema" @submit="onSubmit" @keydown.enter="$event.preventDefault()" autocomplete="off">
                <div class="header o-flex o-flex--center o-flex--justify">
                    <BackButton :callback="backButtonHandler" />
                    <router-link :to="{name: 'home'}" class="header-logo">
                        <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                    </router-link>

                    <LanguageSelection class="type" :dropdownAbsolute="'type2'"/>
                </div>
                <h1 class="mb+">{{$t('signup.new_registration.offer')}}</h1>
                <ul class="list mb+">
                    <li v-for="(e, i) in interests" :key="i">
                        <div class="checkbox">
                            <Field placeholder="meeting" :validateOnInput="false" name="interested_in" v-model="formData.interested_in" type="checkbox" :id="`interest${i}`" :value="e.value"/>
                            <label :for="`interest${i}`">
                                {{ e.code === 'selling_pics' ? $t(`signup.new_registration.interests.${e.code}.${gender === 'man' ? 'sd' : 'sb'}`) : $t(`signup.new_registration.interests.${e.code}`)}}
                            </label>
                        </div>
                    </li>
                </ul>
                <div class="form-group submit-button">
                    <button :disabled="working || disableButton" class="button button--primary w-100">
                        {{$t('signup.new_registration.continue')}}
                    </button>
                </div>

            </Form>
        </article>
    </section>
</template>
<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import { defineRule } from 'vee-validate';
import { required } from '@vee-validate/rules'
import { Form, Field } from 'vee-validate';
import {interests, additional_interests} from "@/service/forms";
import LanguageSelection from "@/components/language/LanguageSelection";
import BackButton from "@/components/generic/BackButton";

export default {
    name: 'YourOffer',
    mixins: [ThemeMixin],
    components: { LanguageSelection, BackButton, Form, Field},
    props: {
        gender: String,
        payload: Object,
        errors: Object,
        working: {
            type: Boolean,
            default: false
        }
    },
    emits: ['nextStep', 'previousStep'],
    data() {
        return {
            theme: '',
            interests: [],
            formData: {
                interested_in: [],
            }
        }
    },

    computed: {
        disableButton() {
            return this.formData.interested_in.length === 0;
        }
    },

    methods: {
        onSubmit() {
            this.$emit('nextStep', this.formData)
        },
        backButtonHandler() {
            this.$emit('previousStep', {current: 'YourOffer'})
        },
    },
    created() {
        defineRule('required', required)
        this.validationSchema = {
            interested_in: 'required'
        }
        this.theme = process.env.VUE_APP_THEME;
        this.interests = [...interests, ...additional_interests]
    },
    mounted() {
        this.$refs.registrationForm.setErrors(this.errors);

        if(this.payload.interested_in) {
            this.$refs.registrationForm.setFieldValue('interested_in', this.payload.interested_in)
        }
    }
}
</script>
<style lang="scss" scoped>
    .offer-article {

        h1 {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #fff;
            margin-bottom: 20px;
        }

        h2 {
            color: #fff;
            text-align: left;
            font-weight: 400;
        }

        p {
            color: #fff;
        }

        .button {
            margin-top: 40px;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 0;
        }

        .checkbox {
            // input {
            //     visibility: hidden;
            //     opacity: 0;
            //     position: absolute;
            //     z-index: -1;
            // }

            label {
                padding: 0 20px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #21252D;
                color: #ffffff;
                font-size: 16px;
                height: 36px;
                border-radius: 18px;
                font-family: "Open Sans", sans-serif;
                cursor: pointer;
                transition: all .3s ease;
                background-color: #111317;
            }

            input:checked + label {
                border-color: #B98A56;
                background-color: #292118;
            }
        }

        @media(max-width: 480px) {

            .form-group {
                .button--primary {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>